
import { defineComponent, PropType } from 'vue'
import Rock from '@/types/Rock'
import { assetForRockAtStage, profileForRock } from '@/lib'
import { POLISH_CYCLES } from '@/types/POLISH_CYCLES'

export default defineComponent({
  props: {
    modelValue: {
      type: Boolean,
      required: true
    },
    shared: {
      type: Boolean,
      default: false
    },
    rock: {
      type: Object as PropType<Rock>,
      required: true
    }
  },
  methods: {
    assetForRockAtStage,
    profileForRock,
    shareRock () {
      navigator.share({
        title: `Check out your friend's ${this.rock.type} rock!`,
        text: 'Your friend polished a virtual rock. Now they want to show you how shiny it is.',
        url: this.urlForRock
      })
    },
    copyShareLink () {
      const input = this.$refs.linkInput as HTMLInputElement
      input.select()
      document.execCommand('copy')
      this.copied = true
    }
  },
  computed: {
    urlForRock (): string {
      return `${location.protocol}//${location.host}${this.$router.resolve({ name: 'Shared' }).path}?type=${encodeURIComponent(this.rock.type)}`
    },
    shareAPIAvailable (): boolean {
      return !!navigator.share
    }
  },
  data () {
    return {
      POLISH_CYCLES,
      copied: false
    }
  }
})
