
import { defineComponent } from 'vue'
import { POLISH_CYCLES } from '@/types/POLISH_CYCLES'
import { ROCK_DATA } from '@/constants'
import Rock from '@/types/Rock'
import ShowcasedRock from '@/components/ShowcasedRock.vue'
import { assetForRockAtStage } from '@/lib'

declare interface TrophyCaseData {
  dialog: boolean,
  selectedRock: null|Rock,
  POLISH_CYCLES: unknown
}

export default defineComponent({
  name: 'TrophyCase',
  components: { ShowcasedRock },
  methods: {
    assetForRockAtStage,
    openDialog (rock: Rock) {
      this.selectedRock = rock
      this.dialog = true
    }
  },
  computed: {
    rocks () { return this.$store.state.rockLists.polished },
    rockData (rock: Rock) {
      return ROCK_DATA[rock.type]
    },
    newCycle () {
      return this.$store.state.rockLists.tumbling.length === 0
    }
  },
  data (): TrophyCaseData {
    return {
      dialog: false,
      selectedRock: null,
      POLISH_CYCLES
    }
  }
})
